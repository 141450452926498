import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed, faBath, faCar, faMapMarked } from '@fortawesome/free-solid-svg-icons';

export type BuySaleRentProps = {
    firebaseApp: firebase.app.App
}
type PropertyDataType = {
    imagesURL: string,
    type: 'Buy' | 'Rent'
    price: number,
    location: string,
    link: string,
    bathRoomNo?: number,
    CarPark?: number,
    land?: number,
    roomNo?: number,
    id: number

}
const PropertyData: PropertyDataType[] = []

export function BuySaleRent(props: BuySaleRentProps) {
    const firebaseApp = props.firebaseApp
    const [data, setData] = useState(PropertyData)
    useEffect(() => {
        firebaseApp.database().ref("/DatabaseWeb/property-data").on('value', d => {
            const data = d.val()
            if (data) {
                const property: PropertyDataType[] = []
                for (let x of Object.keys(data)) {
                    property.push(data[x])
                }
                setData(property)
            }
        })

    }, [firebaseApp])

    console.log("DATA PROPERTY", data)


    const isiSlide: any[] = []
    for (const x of data) {
        isiSlide.push(
            <div key={x.id}>
                <div
                    className="square2"
                    style={{ backgroundImage: `url(${x.imagesURL})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                    onClick={() => {
                        window.location.href = x.link
                    }}
                >
                    <div className="content2">
                        {x.location} <br />
                        <FontAwesomeIcon icon={faBed} /> {x.roomNo} | <FontAwesomeIcon icon={faBath} /> {x.bathRoomNo} |
                        <FontAwesomeIcon icon={faCar} /> {x.CarPark} | <FontAwesomeIcon icon={faMapMarked} /> {x.land} M2
                    </div>
                </div>

            </div>
        )
    }

    console.log(isiSlide)
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };

    return (
        <>
            <Helmet>
                <style>
                    {
                        `
                        .square2 {
                            position: relative;
                            width: 100%;
                            background: #fff;
                            cursor: pointer;
                          } 
                          .square2:after {
                            content: "";
                            display: block;
                            padding-bottom: 80%;
                          }
                          .square2:hover .content2 {
                            background: #222c45c4;  
                          }
                          .square2 .content2 {
                            position: absolute;
                            width: 100%;
                            bottom: 0px;
                            background: #222c4514; /* #222c45c4 */
                            padding: 15px;
                            color: #fff;

                        }

                          `
                    }
                </style>
            </Helmet>
            <Row>
                <Col>
                    <Slider {...settings}>
                        {isiSlide}
                    </Slider>
                </Col>
            </Row>
        </>
    )
}

import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import localRE from '../images/local-realestate-agent.jpg' 
import mermap from '../images/mremap.jpg' 


  
export type MREMapProps = {}
export function MREMap(props: MREMapProps) {
    return (
        <Container>
            <Row  style={{background: '#fff'}}>
                <Col md={5} style={{padding: 0}}>
                    <img src={mermap} alt="" />
                </Col>
                <Col md={7}  style={{padding: 0}}>
                    <img src={localRE} alt="" />
                    <div className="show-contact">
                        <a href="https://mclarenrealestate.com.au/contact/" className="btn btn-twitter" target="_blabk" >Contact Us</a>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
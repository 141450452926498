import React from 'react'
import { Container, Row, Col, Carousel, CarouselIndicators, CarouselItem } from 'reactstrap'
import kakek from '../images/testibg.jpg'
const items = [
    {
        name: "Tara-Jane Carter, July 2020",
        komentar: "The McLaren team are fabulous! Great communication, if any issues they are quick to assist. Super friendly and easy to deal with. It was absolutely pleasure dealing with them.",
        star: 5,
        jonTitle: 'Professionalism, Quality, Responsiveness, Value'
    }, 
    {
        name: "Dijana Green, August 2020 ",
        komentar: "McLaren help make your dreams come true. We had an excellent experience with McLaren the last 2 months purchasing our home. They are honest, genuine friendly and really care. They answer all calls and text messages without delay and its never an issue to respond., They even thought of our personal needs on moving day. A great working partnership is what they set up. We couldn’t be more complimentary and certainly recommend McLaren to anyone!",
        star: 5,
        jonTitle: 'Professionalism, Quality, Responsiveness, Value'
    } 
]
export type TestimonialProps = {}
export function Testimonial(props: TestimonialProps) {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex: any) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };
    return (
        <section style={{ background: `url(${kakek})`, color: '#fff', backgroundPosition: 'center', backgroundSize: 'cover', paddingBottom: 50 }}>
            <Container>
                <Row  >
                    
                    <Col md={12} style={{ textAlign: 'center', paddingTop: '10%' }} >
                        <h3>CLIENT TESTIMONIALS</h3>
                        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                            <CarouselIndicators
                                items={items}
                                activeIndex={activeIndex}
                                onClickHandler={goToIndex}
                            />
                            {items.map(item => {
                                return (
                                    <CarouselItem
                                        onExiting={onExiting}
                                        onExited={onExited}
                                        key={item.name}
                                    >
                                        <div >
                                            <p>{item.komentar}</p>
                                            <p>{item.name}</p>
                                            <p>{item.jonTitle}</p>
                                        </div>
                                    </CarouselItem>
                                );
                            })}
                            <a
                                className="carousel-control-prev"
                                data-slide="prev"
                                href="#pablo"
                                onClick={e => {
                                    e.preventDefault();
                                    previous();
                                }}
                                role="button"
                            >
                                <i className="now-ui-icons arrows-1_minimal-left"></i>
                            </a>
                            <a
                                className="carousel-control-next"
                                data-slide="next"
                                href="#pablo"
                                onClick={e => {
                                    e.preventDefault();
                                    next();
                                }}
                                role="button"
                            >
                                <i className="now-ui-icons arrows-1_minimal-right"></i>
                            </a> 
                        </Carousel>
                    </Col>
                </Row>
            </Container>

        </section >
    )
}
import React, { useState, useEffect } from 'react'
import { Carousel, CarouselItem, CarouselIndicators, Row, Col } from "reactstrap";
import { Helmet } from 'react-helmet';


export type PropertyInsightProps = {
    firebaseApp: firebase.app.App
}



export function PropertyInsight(props: PropertyInsightProps) {
    const firebaseApp = props.firebaseApp
    const [propertyInsight, setPropertyInsight] = useState([])


    useEffect(() => {
        firebaseApp.database().ref("/DatabaseWeb/insight-property-report").on('value', d => {
            const data = d.val()
            if (data) {
                const property = []
                for (let x of Object.keys(data)) {
                    property.push(data[x])
                }
                setPropertyInsight(property as any)
            }
        })

    }, [firebaseApp])
    console.log("propertyInsight", propertyInsight)
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === propertyInsight.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? propertyInsight.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex: any) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };
    return (
        <div style={{ background: '#fff', padding: 35 }} >
            <Helmet>
                <style>
                    {
                        `
                    .carousel .carousel-inner {
                        box-shadow: 0 10px 25px 0 rgba(0,0,0,0) !important;
                      }
                    
                    `
                    }
                </style>
            </Helmet>
            <h3 style={{ textTransform: 'uppercase', marginBottom: 30, fontWeight: 800 }}>Insight & Property Reports</h3>
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                <CarouselIndicators
                    items={propertyInsight}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                />
                {propertyInsight.map((item: any) => {
                    const isi: any = []

                    for (const x of Object.keys(item)) {
                        if (x !== 'location') {
                            let prc = ''
                            if (item[x] < 1) {
                                prc = `${formatMoney(item[x] * 100, 2)} %`
                            } else {
                                prc = `$ ${formatMoney(item[x], 0)}`
                            }
                            isi.push(<Col  key={`${item.location}-${x}`} >
                                <h5 style={{ textTransform: 'uppercase', fontSize: 16 }}>{item.location} {x}</h5>
                                <span className="price"> {prc} </span>
                            </Col>)
                        }

                    }


                    return (
                        <CarouselItem
                            onExiting={onExiting}
                            onExited={onExited}
                            key={item.location}
                        >
                            <Row>

                                {isi}
                            </Row>
                        </CarouselItem>
                    );
                })}

            </Carousel>
        </div>
    )
}

const formatMoney = (amount: any, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i: any = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j: any = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
};
import React from 'react'
import concierge from '../images/concierge.png'
import corImg from '../images/concior-bg.jpg'
import { Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export type ConciergeProps = {}

export function Concierge(props: ConciergeProps) {
    return (
        <section style={{ background: 'rgb(33 43 69)' }}>

            <Row>
                <Col md={6} style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${corImg})` }}>
                    <div style={{ maxWidth: 500, float: "right", marginTop: '20%' }}>
                        <img src={concierge} alt="" width="80%" />
                    </div>
                </Col>
                <Col md={6} style={{ backgroundSize: 'cover', backgroundPosition: 'center', color: '#fff' }}>
                    <div style={{ maxWidth: 500, margin: '50px 15px' }}>
                        <h3 className="consior" >FACILITATE</h3>
                        <h4 style={{margin: '5px 0'}}><FontAwesomeIcon icon={faCheck} /> Buyer Readiness Program</h4>
                        <p style={{marginLeft: 27}}>We prepare and coach you, so you are ready to buy that perfect property!</p>
                        <h3 className="consior">LOCATE</h3>
                        <h4 style={{margin: '5px 0'}}><FontAwesomeIcon icon={faCheck} /> Exclusive access to more properties</h4>
                        <p  style={{marginLeft: 27}}>On and off market<br /> Private listings too! </p>
                        <h3 className="consior">NEGOTIATE</h3>
                        <h4 style={{margin: '5px 0'}}><FontAwesomeIcon icon={faCheck} /> We know the market better than anyone</h4>
                        <p style={{marginLeft: 27}}>Local agents = inside intel<br />We’ll negotiate on your behalf</p>
                    </div>

                </Col>
            </Row>

        </section>
    )
}
import React from 'react'
import {  Row, Col,  Container } from 'reactstrap'
import { StartNow } from './StartNow'
// import { TalkToUs } from './TalkToUs'


export type ContactUsProps = {
  uid: string,
  firebaseApp: firebase.app.App
  data?: any
}
export function ContactUs(props: ContactUsProps) {
    return (
        <section style={{marginTop: 50}}>
          <Container>
            <Row className="justify-content-md-center"> 
              <Col md={8}>
                <StartNow data={props.data} {...props}/> 
              </Col>
              
            </Row>
          </Container>
        </section>
    )
}
/*
<Col md={6}>
                <TalkToUs {...props}/> 
              </Col>
*/

/*
<div style={{textAlign: "center", margin: '50px 0'}}>
                <p style={{fontWeight: 500}}>OR…</p>

<p style={{fontWeight: 500}}>If you are thinking about selling your home, or if you have questions regarding the current market in your area, <br/> TALK TO US!
 </p>
<div> <a href="https://mclarenrealestate.com.au/contact/" rel="noopener noreferrer" style={{backgroundColor: "#b0bf28", fontSize: 16, fontWeight: 800}} className="btn btn-primary" target="_blank">CONTACT US
</a></div>
</div>

*/
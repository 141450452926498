import React from "react"
import "./layout.css"
import { Row, Col, Container } from "reactstrap"
import '../themes/css/bootstrap.min.css'
import '../themes/css/now-ui-kit.min.css'
import mreLogo from '../images/mre-logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faEnvelope  } from '@fortawesome/free-solid-svg-icons'

type LayoutProps = {
  header: any
  children: any
}

const Layout = (props: LayoutProps) => {

  return (
    <>
      {props.header}
      <div
        
      >
        <main>{props.children}</main>

      </div>
      <footer style={{ background: '#212944', textAlign: "center", color: '#fff', fontSize: 12, padding: '10px 0' , bottom: 0, width: '100%' }}>
        <Container>
          <Row style={{ textAlign: 'left', padding: 30 }}>
            <Col md={3} >
              <img src={mreLogo} alt="" /> 
            </Col>
            <Col md={3} >
               
              <p>The agency is owned and operated by innovative Director Lincon McLaren who has lived with his family in the Macarthur for more than 40 years.</p>
            </Col>
            <Col md={3} >
              <h4 style={{ marginTop: 0 }}>Address:</h4>
              <strong>Narellan</strong><br />
              Suite 8 & 9,<br />
              338 Camden Valley Way<br />
              Narellan NSW 2567<br />
              <br />
              <strong>Harrington Park</strong><br />
              Shop 9, 23 Fairwater Drive<br />
              Harrington Park Plaza<br />
            </Col> 
            <Col md={3} >
              <h4 style={{ marginTop: 0 }}>Trading Hours:</h4>
                Mon – Fri 9am – 5pm<br />
                Sat 9am – 5pm<br />
                Sun Closed<br />
              <h4> Contact:</h4>
              <a href="tel: =61 02 4648 3777"><FontAwesomeIcon icon={faPhoneAlt}/> (02) 4648 3777</a><br />
              <a href="mailto:hello@mclarenrealestate.com.au"><FontAwesomeIcon icon={faEnvelope}/> hello@mclarenrealestate.com.au</a>
            </Col>
          </Row>
        </Container>
        <p style={{background: '#161d31', margin: 0, padding: 5}}>
          © {new Date().getFullYear()} McLaren Real Estate, Powered by <a href="https://geonet.me" target="_blank" rel="noopener noreferrer">Geonet.me</a>
        {` `}
        </p>
      </footer>
    </>
  )
}

export default Layout


import React, { useEffect, useState } from "react"
import Layout from "./components/layout"
import logo from './images/mre250-grey.png'
import { Row, Col, Card, Container } from "reactstrap"
import "nouislider/distribute/nouislider.css";
import imageBanner from './images/banner.jpg'
import { PropertyInsight } from "./components/propertyInsight";
import { BuySaleRent } from "./components/buy-sale-rent";
import { MREMap } from "./components/mreMap";
import { Concierge } from "./components/concierge";
import { Testimonial } from "./components/testi";
import { ContactUs } from "./components/contactUs";

type mainProps = {
  uid: string,
  firebaseApp: firebase.app.App
}

const App = (props: mainProps) => {

  const [name, setName] = useState("")
  const [leads, setLeads] = useState()
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    console.log("urlParams", urlParams)
    if (urlParams.get('id')) {
      const id = urlParams.get('id')
      props.firebaseApp.database().ref(`facebookLeads/${id}`).once("value", (d: any) => {
        const data= d.val()
        setLeads(data)
        setName(data.Name)

      })
    }
  }, [props.firebaseApp])

  console.log("Name", name)

  let marginTopBanner = -250
  let griting = <span/>

  if(name!== "") {
    marginTopBanner = -560
    griting = (
      <div className="griting">
        <p style={{fontWeight: 500}}> Dear <span style={{color: '#b0bf27', fontWeight: 600}}>{name}</span></p>
<p style={{fontWeight: 500}}>McLaren Real Estate are your local experts, with property and market insights and exclusive access to more listings across the area. Browse our listings or talk to us today!</p>

      </div>
    )
  }

  return (
    <Layout header={<Header />}>
      <section style={{ backgroundImage: `url(${imageBanner})`, backgroundSize: 'cover', minHeight: 600, backgroundPosition: 'center' }}>
      </section>
      <section style={{ marginTop: marginTopBanner }}>
        <Container>
          <Row>
            <Col>
              <h3 className="header-font" style={{ textAlign: 'center', fontSize: '5em', color: '#fff' }}>‘Where it all begins' </h3>
              {griting}
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={12}>
              <MREMap />
              <Card>
                <PropertyInsight firebaseApp={props.firebaseApp} />
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <h3 style={{ textAlign: 'center' }}>Our Featured Listings</h3>
        <BuySaleRent firebaseApp={props.firebaseApp} />
      </section>
      <Concierge />
      <section style={{ backgroundColor: '#b0bf27', padding: 30 }}>
        <Container>
          <Row style={{ textTransform: 'uppercase', fontSize: 20, fontWeight: 800 }}>
            <Col md={6} style={{ cursor: 'pointer' }} onClick={() => {
              window.location.href = "https://mclarenrealestate.com.au/contact/"
            }} >
              <span className="cicleAction">
                Talk <br />To Us
              </span>
              <h4 className="talk" style={{}}>CONTACT US TODAY TO BOOK YOUR MRE CONCIERGE</h4>
            </Col>
            <Col md={6} style={{ cursor: 'pointer' }} onClick={() => {
              window.location.href = "https://mclarenrealestate.com.au/status/current/"
            }}>
              <span className="cicleAction" style={{ padding: '19px 5px' }}>
                Search<br />
                Now
              </span>
              <h4 className="talk" style={{}}>search all the latest MRE listings on our website!</h4>
            </Col>
          </Row>
        </Container>

      </section>
      <ContactUs data={leads} {...props} />
      <Testimonial />

    </Layout>
  )
}

export default App;

const Header = () => {

  return (
    <header
      style={{
        background: `#212944`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          textAlign: 'center'
        }}
      >
        <img src={logo} alt="" style={{ margin: '10px  auto' }} />
      </div>
    </header>
  )

}



import React, { useEffect, useState } from 'react'
import { Card, CardBody, Form, Row, Col, FormGroup, Input, Button, Label } from 'reactstrap'
import team from '../images/team.png'

export type StartNowProps = {
  uid: string,
  firebaseApp: firebase.app.App
  data?: any
}
export function StartNow(props: StartNowProps) {

  const [suberb, setSuberb] = useState("")
  const [story, setStory] = useState("")
  const [bedroom, setBedroom] = useState("")
  const [bathroom, setBathroom] = useState("")
  const [garage, setGarage] = useState("")
  const [livingArea, setLivingArea] = useState("")
  const [pool, setPool] = useState("")
  const [priceRage, setPriceRage] =  useState("") //useState({ from: '', to: '' })
  const [spesialRequest, setSpesialRequest] = useState("")
  // const [ver, setVer] = useState(0)
  const [message, setMessage] = useState("")
  const [whatYouWant, setWhatYouWant] = useState("")
  //const leads = props.data
  const defname = ""//props.data && props.data.Name ? props.data.Name : ""
  const [name, setName] = useState(defname)
  const defemail = ""; //props.data && props.data.Email ? props.data.Email : ""
  const [email, setEmail] = useState(defemail)
  const defPhone = "";//props.data && props.data["Phone Number"] ? props.data["Phone Number"] : ""
  const [Phone, setPhone] = useState(defPhone)
  const defPostcode = ""; //props.data && props.data["Post Code"] ? props.data["Post Code"] : ""
  const [Postcode, setPostCode] = useState(defPostcode)
  const [submited, setSubmited] = useState(false)

  useEffect(() => {
    const defname = props.data && props.data.Name ? props.data.Name : ""
    const defemail = props.data && props.data.Email ? props.data.Email : ""
    const defPhone = props.data && props.data["Phone Number"] ? props.data["Phone Number"] : ""
    const defPostcode = props.data && props.data["Post Code"] ? props.data["Post Code"] : ""
    setName(defname)
    setPhone(defPhone)
    setPostCode(defPostcode)
    setEmail(defemail)
  }, [props.data])



  const formSubmit = async (evt: any) => {
    evt.preventDefault()
    console.log("MASUK")
    const data = {
      suberb, story, bedroom, bathroom, garage, livingArea, pool, priceRage, spesialRequest, message, name, email, Phone, Postcode, whatYouWant
    }
    await props.firebaseApp.database().ref(`startLeads/${props.uid}`).set(data)
    setSubmited(true)
    
  }
  if (submited) {
    return <Card>
      <CardBody>
        Thank you for submitting your information to MRE. Our team will be in touch very soon.  You can also find more information on <a href="https://mclarenrealestate.com.au">https://mclarenrealestate.com.au</a>
      </CardBody>
    </Card>
  }
  return (
    <Card>
      <CardBody>
        <h3 style={{ textAlign: 'center', margin: 0 }}>LET’S GET STARTED!</h3>
        <h4 style={{ textAlign: 'center', marginTop: 0 }}>Tell us about your ideal home</h4>
        <Form style={{ marginBottom: 0 }} onSubmit={formSubmit}>
          <Row >
            <Col md={6}>
              <FormGroup>
                <Input
                  placeholde="Do you want to?" type="select" value={suberb}
                  onChange={(evt) => {
                    // console.log(evt.target.value)
                    setSuberb(evt.target.value)
                  }}
                >
                  <option value=""> Which suburb are you looking for?</option>
                  <option value="Narellan">Narellan</option>
                  <option value="Mount Annan">Mount Annan</option>
                  <option value="Harrington Park">Harrington Park</option>
                  <option value="Camden Park">Camden Park</option>
                  <option value="Narellan Vale">Narellan Vale</option> 
                  <option value="Camden">Camden</option>
                  <option value="Camden South">Camden South</option>
                  <option value="Elderslie">Elderslie</option>
                  <option value="Currans Hill">Currans Hill</option>
                  <option value="Oran Park">Oran Park</option>
                  <option value="Gregory Hills">Gregory Hills</option>
                  <option value="Cobbitty">Cobbitty</option>
                  <option value="Grasmere">Grasmere</option>
                  <option value="Emerald Hills">Emerald Hills</option>
                  <option value="Other">Other</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input
                  value={story}
                  onChange={(evt) => {
                    // console.log(evt.target.value)
                    setStory(evt.target.value)
                  }}
                  placeholde="Do you want to?" type="select">
                  <option value=""> Single or double storey home?</option>
                  <option value="Single">Single</option>
                  <option value="Double">Double</option>
                  <option value="Either">Either</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input value={bedroom}
                  onChange={(evt) => {
                    // console.log(evt.target.value)
                    setBedroom(evt.target.value)
                  }}
                  placeholde="Do you want to?" type="select">
                  <option value=""> How many bedrooms?</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6+">6+</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input value={bathroom}
                  onChange={(evt) => {
                    // console.log(evt.target.value)
                    setBathroom(evt.target.value)
                  }}
                  placeholde="Do you want to?" type="select">
                  <option value=""> How many bathrooms?</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3+">3+</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input value={garage}
                  onChange={(evt) => {
                    // console.log(evt.target.value)
                    setGarage(evt.target.value)
                  }}
                  placeholde="Do you want to?" type="select">
                  <option value=""> How many garages?</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3+">3+</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input value={livingArea}
                  onChange={(evt) => {
                    // console.log(evt.target.value)
                    setLivingArea(evt.target.value)
                  }}
                  placeholde="Do you want to?" type="select">
                  <option value=""> How many living areas?</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3+">3+</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input value={pool}
                  onChange={(evt) => {
                    // console.log(evt.target.value)
                    setPool(evt.target.value)
                  }}
                  placeholde="Do you want to?" type="select">
                  <option value="">Would you like a pool?</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                  <option value="Either">Either</option>
                </Input>
              </FormGroup>
            </Col>

            <Col md={6}>
            <FormGroup>
                <Input value={pool}
                  onChange={(evt) => {
                    // console.log(evt.target.value)
                    setPriceRage(evt.target.value)
                  }}
                  placeholde="Do you want to?" type="select">
                  <option value="">Price Range ?</option>
                  <option value="Below $400,000">Below $400,000</option>
                  <option value="$400,000 - $600,000No">$400,000 - $600,000</option>
                  <option value="$600,000 - $800,000">$600,000 - $800,000</option>
                  <option value="$800,000 - $1,000,000">$800,000 - $1,000,000</option>
                  <option value="Above $1,000,000">Above $1,000,000</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={12}>
              <p style={{ fontSize: '.9em', fontWeight: 500, marginBottom: 0 }}>Any special requirements?</p>
              <FormGroup check className="form-check-radio" inline>
                <Label check>
                  <Input
                    name="inlineRadioOptions"
                    type="radio"
                    onClick={() => {
                      setSpesialRequest("YES")
                    }}
                  ></Input>
                  Yes <span className="form-check-sign"></span>
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" inline>
                <Label check>
                  <Input
                    name="inlineRadioOptions"
                    type="radio"
                    onClick={() => {
                      setSpesialRequest("NO")
                    }}
                  ></Input>
                  No <span className="form-check-sign"></span>
                </Label>
              </FormGroup>
            </Col>
            <Col md={12} style={{ display: spesialRequest === 'YES' ? '' : 'none' }}>
              <Input
                rows="3"
                type="textarea"
                placeholder="Message"
                value={message}
                onChange={(evt) => {
                  // console.log(evt.target.value)
                  setMessage(evt.target.value)
                }}
              ></Input>
            </Col>
            
            <Col md={12}>
              <h4>Please enter your details below</h4>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input
                  placeholder="Enter Name"
                  type="text"
                  value={name}
                  onChange={(evt) => {
                    // console.log(evt.target.value)
                    setName(evt.target.value)
                  }}

                ></Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input
                  placeholder="Enter Email"
                  type="email"
                  value={email}
                  onChange={(evt) => {
                    // console.log(evt.target.value)
                    setEmail(evt.target.value)
                  }}
                ></Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input
                  placeholder="Enter Phone Number"
                  type="text"
                  value={Phone}
                  onChange={(evt) => {
                    // console.log(evt.target.value)
                    setPhone(evt.target.value)
                  }}
                ></Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input
                  placeholder="Enter Post Code"
                  type="text"
                  value={Postcode}
                  onChange={(evt) => {
                    // console.log(evt.target.value)
                    setPostCode(evt.target.value)
                  }}
                ></Input>
              </FormGroup>
            </Col>
            
          </Row>
          <Button color="twitter" type="submit" style={{ width: '100%' }}> Submit </Button>
        </Form>
        
      </CardBody>

    </Card>
  )
}

/*

              <Col md={12}>
              <div style={{ textAlign: "center", margin: '20px 0' }}>
                <p style={{ fontWeight: 600 }}>OR.... SELLING YOUR HOME?</p> 
                <p style={{ fontWeight: 500 }}>If you are thinking about selling your home, or if you have questions regarding the current market in your area, <br /> TALK TO US!</p>
              </div>
            </Col>

            <Col md={12}>
              <FormGroup>
                <Input value={whatYouWant}
                  onChange={(evt) => {
                    // console.log(evt.target.value)
                    setWhatYouWant(evt.target.value)
                  }} placeholde="Do you want to?" type="select">
                  <option value="">Do you want to?</option>
                  <option value="Buy">Buy a new home</option>
                  <option value="Sale">Sell your Your Home</option>
                  <option value="Rent">Rent a Home</option>
                  <option value="Buy Commercial">Buy Commercial </option>
                </Input>
              </FormGroup>
            </Col>

            <img src={team} alt="" style={{ marginTop: 40,  }} />
*/
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase'; 
const firebaseConfig = {
  apiKey: "AIzaSyDs0YHeCLG8xiOXSIhtvRXrJpkUKxgOCEI",
  authDomain: "geonet-me.firebaseapp.com",
  databaseURL: "https://m-r-e.firebaseio.com/",
  projectId: "geonet-me",
  storageBucket: "geonet-me.appspot.com",
  messagingSenderId: "275007142037",
  appId: "1:275007142037:web:33c0d40bdce21b2bd3c39c",
  measurementId: "G-ZRVV81ZHE4"
};

const runBootstrap = async () => {
  const firebaseApp = firebase.initializeApp(firebaseConfig)
  let called = false;
  const unSubscribe = firebaseApp.auth().onAuthStateChanged((user:   any) => {
    if (called) {
      return;
    }
    if (user) {
      console.log(user)
      called = true;
      console.log("USER", user)
      ReactDOM.render(<App uid={user.uid} firebaseApp={firebaseApp} />, document.getElementById('root'));
      unSubscribe();
    } else {
      // console.log("masuk tidak ada user")
      console.error('user null. berarti login di sini');
      firebaseApp.auth().signInAnonymously()
        .then(d => {
          console.log('User data: ', d);
        }).catch(exc => {
          console.error('Gagal login, error: ', exc);
        });
    }
  });
}
runBootstrap()

serviceWorker.unregister();
